const durationOptions = [
  { id: '5', name: '5' },
  { id: '10', name: '10' },
  { id: '15', name: '15' },
  { id: '20', name: '20' },
  { id: '30', name: '30' },
  { id: '45', name: '45' },
  { id: '60', name: '60' },
  { id: '75', name: '75' },
];

export default durationOptions;
