import React, { PureComponent } from 'react';
import { List, TextField, Datagrid, FunctionField, BooleanField, SelectField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import DotsMenu from '../../../components/DotsMenu/DotsMenu';
import difficulty from '../../../constants/difficulty';
import roles from '../../../constants/roles';

const styles = {
  center: {
    textAlign: 'center',
  },
  nameCol: {},
  action: {
    textAlign: 'center',
  },
};

class ListPrograms extends PureComponent {
  handleAction = (action, record) => {
    if (action && action.toLowerCase() === 'edit') {
      this.props.history.push(`/programs/${record.id}`);
    }
  };

  render() {
    const { classes, permissions, ...props } = this.props;
    return (
      <List
        {...props}
        exporter={false}
        bulkActions={false}
        sort={{}}
        filterDefaultValues={{ order_by: '-createdAt' }}
        filter={{}}
      >
        <Datagrid rowClick="show">
          <TextField
            label="Program Name"
            source="name"
            sortable={false}
            className={classes.nameCol}
          />
          <FunctionField
            sortable={false}
            className={classes.center}
            headerClassName={classes.center}
            label="Number of Sessions / Is External"
            render={(record) => (record.isExternal ? 'Is External' : record.sessionsCount)}
          />
          <SelectField
            sortable={false}
            className={classes.center}
            choices={difficulty}
            headerClassName={classes.center}
            label="Type"
            source="complexity"
          />
          <BooleanField
            sortable={false}
            className={classes.center}
            headerClassName={classes.center}
            source="isPublic"
          />
          {permissions === roles.superAdmin ? (
            <FunctionField
              cellClassName={classes.action}
              label=""
              onClick={(e) => {
                e.stopPropagation();
              }}
              render={(record) => (
                <DotsMenu onChange={(e) => this.handleAction(e, record)} options={['edit']} />
              )}
            />
          ) : null}
        </Datagrid>
      </List>
    );
  }
}

export default withStyles(styles)(ListPrograms);
