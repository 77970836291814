import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, withStyles } from '@material-ui/core';
import { CREATE, DELETE, crudGetOne } from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import dataProvider from '../../../providers/dataProvider';
import DeleteAlertDialog from '../../../components/DeleteAlertDialog/DeleteAlertDialog';
import DotsMenu from '../../../components/DotsMenu/DotsMenu';
import SessionModal from '../SessionModal/SessionModal';

const styles = () => ({
  rowHover: {
    cursor: 'pointer',
  },
});

class Sessions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openNewSession: false,
      isOpen: false,
      row: null,
      sessions: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.refreshSessions();
  }

  handleCloseModalDelete = () => {
    this.setState({ isOpen: false, row: null });
  };

  handleAction = (action, row) => {
    if (action && action.toLowerCase() === 'delete') {
      this.setState({ isOpen: true, row });
    }
    if (action && action.toLowerCase() === 'edit') {
      this.setState({ openNewSession: true, row });
    }
    if (action && action.toLowerCase() === 'duplicate') {
      this.duplicate(row);
    }
  };

  getMenuList = () => {
    const itemsAlways = ['Edit', 'Duplicate', 'Delete'];
    if (this.state.sessions.length === 1) {
      return itemsAlways;
    }
    return ['Edit', 'Duplicate', 'Delete'];
  };

  duplicate = (row) => {
    dataProvider(CREATE, `sessions/${row.id}/duplicate`, {}).then(() => this.refreshSessions());
  };

  refreshSessions = () => {
    this.setState({ loading: true, sessions: [] });
    dataProvider('GET_ALL', `programs/${this.props.record.id}/sessions`, {}).then((res) => {
      this.setState({ sessions: res.data.sessions, loading: false });
    });
  };

  handleClickOpen = () => {
    this.setState({ openNewSession: true });
  };

  handleClose = () => {
    this.setState({ openNewSession: false, row: {} });
  };

  getListOfDays = (sessions) => {
    if (Array.isArray(sessions)) {
      return sessions.map((s) => s.order);
    }
    return [];
  };

  getLastDay = () => {
    const days = this.getListOfDays(this.state.sessions);
    return days[days.length - 1];
  };

  handleSession = (row) => {
    this.props.history.push(`/sessions/${row.id}`);
  };

  handleDelete = () => {
    dataProvider(DELETE, 'sessions', { id: this.state.row.id }).then(() => {
      this.refreshSessions();
      this.props.getOnePrograms(this.props.record.id);
      this.handleCloseModalDelete();
    });
  };

  renderLoading = () => {
    if (this.state.loading) {
      return (
        <div style={{ padding: '60px 0', width: '100%' }}>
          <LinearProgress />
        </div>
      );
    }
    return null;
  };

  renderTableBody = () => {
    if (!this.state.sessions) {
      return null;
    }
    const { classes } = this.props;
    const { sessions } = this.state;
    return (
      <TableBody>
        {sessions.map((row, i) => (
          <TableRow key={row.id}>
            <TableCell
              className={classes.rowHover}
              component="th"
              scope="row"
              onClick={() => this.handleSession(row)}
            >
              {row.id}
            </TableCell>
            <TableCell className={classes.rowHover} onClick={() => this.handleSession(row)}>
              {row.name}
            </TableCell>
            <TableCell className={classes.rowHover} onClick={() => this.handleSession(row)}>
              {Object.keys(row.durationOptions).join(', ')}
            </TableCell>
            <TableCell className={classes.rowHover} onClick={() => this.handleSession(row)}>
              {row.description}
            </TableCell>
            <TableCell align="right">
              <DotsMenu
                onChange={(e) => this.handleAction(e, row)}
                options={this.getMenuList(row, i)}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  };

  renderModalWindow = () => {
    if (!this.state.openNewSession) {
      return null;
    }
    return (
      <SessionModal
        refreshList={this.refreshSessions}
        programId={this.props.record.id}
        initialValues={this.state.row}
        listDays={this.getListOfDays(this.props.record.sessions)}
        handleClose={this.handleClose}
        isOpen={this.state.openNewSession}
      />
    );
  };

  render() {
    return (
      <>
        <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
          New session
        </Button>
        {this.renderModalWindow()}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="minWidth30px" align="center">
                Id
              </TableCell>
              <TableCell className="minWidth60px" align="right">
                Session Name
              </TableCell>
              <TableCell className="minWidth60px" align="center">
                Duration Options
              </TableCell>
              <TableCell align="right">Description</TableCell>
              <TableCell className="minWidth30px" align="right">
                {' '}
              </TableCell>
            </TableRow>
          </TableHead>
          {this.renderTableBody()}
        </Table>
        {this.renderLoading()}
        <DeleteAlertDialog
          title={
            this.state.row && this.state.row.isBasic
              ? `Delete Basic Level Session`
              : `Delete Session`
          }
          subtitle="Are you sure you want to delete session?"
          description={
            this.state.row && this.state.row.isBasic
              ? ` If you continue, the basic level session will be permanently deleted from the sessions in this program.`
              : `If you delete program, all session information will be deleted and customers will no longer have access to it.`
          }
          record={this.state.row}
          open={this.state.isOpen}
          handleClose={this.handleCloseModalDelete}
        >
          <Button color="secondary" style={{ color: '#f44336' }} onClick={this.handleDelete}>
            DELETE
          </Button>
        </DeleteAlertDialog>
      </>
    );
  }
}

const getOnePrograms = (id) => {
  return crudGetOne('programs', id, '/programs', true);
};
const enhance = compose(withRouter, connect(undefined, { getOnePrograms }));

Sessions.propsType = {
  source: PropTypes.string,
  values: PropTypes.object.isRequired,
  permissions: PropTypes.string.isRequired,
};

export default withStyles(styles)(enhance(Sessions));
