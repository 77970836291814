/* eslint-disable */
import { stringify } from 'query-string';
import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from 'react-admin';

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = (type, resource, params) => {
    // @fix for admin resource
    // react admin doesn't see the same resources and we should proxy admins to users
    if (resource === 'admins') {
      resource = 'users';
    }
    let url = '';
    const options = {};
    switch (type) {
      case GET_LIST: {
        const { page, perPage } = params.pagination;
        const { ...additionalFields } = params.additionalFields;
        let queryString = '';
        if (resource !== 'admin/advertisement') {
          const query = {
            limit: perPage,
            offset: (page - 1) * perPage,
            ...additionalFields,
            ...params.filter,
            sort: params.sort.field,
            order: params.sort.order,
          };
          queryString = `?${stringify(query)}`
        }

        options.method = 'GET';
        url = `${apiUrl}/${resource}${queryString}`;
        break;
      }
      case GET_ONE:
        options.method = 'GET';
        url = `${apiUrl}/${resource}`;
        if (params && params.id) {
          url = `${url}/${params.id}`;
        }
        break;
      case GET_MANY: {
        const query = {
          // filter: JSON.stringify({ id: params.ids }),
        };
        options.method = 'GET';
        url = `${apiUrl}/${resource}?${stringify(query)}`;
        break;
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
          sort: JSON.stringify([field, order]),
          range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
          filter: JSON.stringify({
            // ...params.filter,
            [params.target]: params.id,
          }),
        };
        options.method = 'GET';
        url = `${apiUrl}/${resource}`;
        if (params.target) {
          url = `${url}/${params.id}/${params.target}`;
        }
        break;
      }
      case 'GET_ALL':
        options.method = 'GET';
        url = `${apiUrl}/${resource}`;
        if (params?.urlParams) {
          options.urlParams = params.urlParams;
        }
        break;
      case UPDATE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'PUT';
        // options.body = JSON.stringify(params.data);
        options.body = params.data;
        break;
      case CREATE:
        url = `${apiUrl}/${resource}`;
        options.method = 'POST';
        // options.body = JSON.stringify(params.data);
        options.body = params.data;
        break;
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'DELETE';
        break;
      /// ///////////////
      // custom actions
      /// //////////////
      case 'CREATE_MUX_UPLOAD':
        url = `${apiUrl}/${resource}`;
        options.method = 'POST';
        break;
      case 'UPLOAD_FILE':
        url = `${apiUrl}/${resource}`;
        options.method = 'POST';
        options.body = params.data;
        options.onUploadProgress = params.onUploadProgress || null;
        break;
      case 'SIGN_URL':
        url = `${apiUrl}/${resource}`;
        options.method = 'GET';
        options.urlParams = params.urlParams;
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params) => {
    const { headers, data } = response;
    // @fix for admin resource
    // react admin doesn't see the same resources and we should proxy admins to users
    if (resource === 'admins') {
      resource = 'users';
    }
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        // if we are looking for nested resources
        if (params.target && params.target === 'sessions') {
          return {
            data: data.sessions,
            total: data.total,
          };
        }
        if (params.target && params.target === 'stories') {
          return {
            data: data.items,
            total: data.total,
          };
        }
        if (resource === 'users') {
          return {
            data: data.users,
            total: data.total,
          };
        }
        if (resource === 'exercise') {
          return {
            data: data.items,
            total: data.total,
          };
        }
        if (resource === 'programs') {
          const newList = data.programs.map((item) => {
            const { introScreens, ...rest } = item;
            return rest;
          });
          return {
            data: newList, // data.programs,
            total: data.total,
          };
        }
        if (resource === 'admin/products') {
          return {
            data: data.products,
            total: data.total,
          };
        }
        if (resource === 'admin/advertisement') {
          return {
            data: data.products,
            total: data.total,
          };
        }
        if (resource === 'admin/questions') {
          return {
            data: data.questions,
            total: data.total,
          };
        }
        if (resource.search('stories') !== -1) {
          return {
            data: data.items,
            total: data.total,
          };
        }
        // default return
        return { data };
      case GET_MANY:
        if (resource === 'admin/products/programs') {
          return {
            data: data.data,
            total: data.total,
          };
        }
        if (resource.search('stories')) {
          return {
            data: data.items,
            total: data.total,
          };
        }
      case CREATE:
        return { data: { ...params.data, id: data.id } };
      case DELETE:
        return { data: { ...params.data, id: params.id } };
      default:
        return { data };
    }
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, resource, params) => {
    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
          }),
        ),
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }));
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'DELETE',
          }),
        ),
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }));
    }
    const { url, options } = convertDataRequestToHTTP(type, resource, params);
    return httpClient(url, options).then((response) => {
      return convertHTTPResponse(response, type, resource, params);
    });
  };
};
